/* You can add global styles to this file, and also import other style files */

/**************Fonts Embedding************************/
@font-face {
	font-family: "Frutiger-Roman";
	font-weight: 400;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Roman.otf"); */
	
	src: url('assets/fonts/FrutigerRoman.eot'); 
    src: url('assets/fonts/FrutigerRoman.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/FrutigerRoman.woff') format('woff'),
         url('assets/fonts/FrutigerRoman.ttf')  format('truetype'),
         url('assets/fonts/FrutigerRoman.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Light";
	font-weight: 300;
 	font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Light.otf"); */
	
	src: url('assets/fonts/FrutigerLight.eot');
    src: url('assets/fonts/FrutigerLight.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/FrutigerLight.woff') format('woff'),
         url('assets/fonts/FrutigerLight.ttf')  format('truetype'),
         url('assets/fonts/FrutigerLight.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Bold";
	font-weight: 600;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Bold.otf"); */
	
	src: url('assets/fonts/FrutigerBold.eot');
    src: url('assets/fonts/FrutigerBold.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/FrutigerBold.woff') format('woff'),
         url('assets/fonts/FrutigerBold.ttf')  format('truetype'), 
         url('assets/fonts/FrutigerBold.svg') format('svg');
}
/**************Fonts Embedding************************/

.btn.removestyle{
    background: 0 0;
    border: none;
    padding: 0;
    border-radius: 0px;
}
.btn.removestyle:focus{
    border: 1.5px solid #0470c5;
    outline: 0!important;
}


/* You can add global styles to this file, and also import other style files */
body{
    background-color: rgba(242,242,242,1)!important;
    font-size: 14px;
    font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;
    font-size: 14px;
    color: #424249;
}
.inputFieldLabel{
    color: #424249 !important;
}
figure{
    margin: 0px !important;
}
.modal-content{
    border-radius: 0px;
}
.btn{
    border-radius: 2px;
}
button.invisiblebtn{
    border-color: none;
    outline-color: none;
    padding: 0;
}
button.invisiblebtn.btn-link:focus{
    border: 1px solid #0470c5;
}
/* button.invisiblebtn:focus{
    border-width: 1px;
    border-style: solid;
    outline: 0!important;
    box-shadow: none;
} */
button.invisiblebtn:focus{
    border: 1px solid;
    box-shadow: 0 6px 12px rgba(0,0,0,.35)!important;
}
button.invisiblebtn:hover{
    box-shadow: 0 6px 12px rgba(0,0,0,.35)!important;
}
button.invisiblebtn:not(:hover):not(:focus){
    border:none !important;
    outline: none !important;
}
.btn.btn-primary.btn-primary-link{
    /* height: 44px; */
    color: #0470C5 !important;
    background-color: transparent !important;
    font-size: 16px;
    font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    border:2px solid #0470c5;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}
.btn.btn-primary.btn-primary-link:focus{
    outline-style: dashed;
    outline-color: #0470C5;
    outline-width: 3px;
    border: none;
    box-shadow: 0 0 18px 0 #1595fa;
}
.btn.btn-primary.btn-primary-link:hover{
    background: #0470c5 !important;
    transition: background .25s ease;
    -webkit-transition: background .25s ease;
    -o-transition: background .25s ease;
    -moz-transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0!important;
}
.btn:not(.btn-primary-link):not(.btn-secondary):not(.btn-link) :focus{
    box-shadow: none !important;
}
.btn.btn-primary:not(.invisiblebtn){
    background-color: #0470c5;
}
.btn.btn-primary:not(.invisiblebtn):hover{
    background: #59B2F9;
    box-shadow: 0 6px 12px rgba(0,0,0,.35)!important;
    transition: background .25s ease,box-shadow .25s ease;
    color: #fff;
    outline-style: none!important;
    border-color: #59B2F9 !important;
}
.btn.btn.btn-primary:not(.invisiblebtn):focus{
    outline-style: dashed;
    outline-color: #0470C5;
    outline-width: 3px;
    border: none;
    box-shadow: 0 0 18px 0 #1595fa;
}
.btn.btn-secondary:not(.invisiblebtn){
    height: 38px;
    border-radius: 2px;
    border: 2px solid #0470c5;
    font-size: 16px;
    color: #0470c5!important;
    background-color: transparent;
    text-align: center;
}
.btn.btn-secondary:not(.invisiblebtn):focus
{
    outline-style: dashed;
    outline-color: #0470C5;
    outline-width: 3px;
    border: none;
    box-shadow: 0 0 18px 0 #1595fa;
}
.btn.btn-secondary:not(.invisiblebtn):hover
{
    background: #0470c5;
    transition: background .25s ease;
    color: #fff!important;
    outline-style: none!important;
    box-shadow: 0 0!important;
}
.paragraph, p {
    font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;
    font-size: 16px;
    color: #424249;
    line-height: 24px;
}
.paragraph.large, p.large{
    font-size: 16px;
    margin: 0px;
}
h1{
    font-family: Frutiger-Light,Helvetica,Arial,sans-serif;
    color:#424249;
    font-size: 32px;
    padding:0px;
    margin: 0px;
}
h2{
    font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;
    color: #424249;
    font-size: 22px;
    padding:0px;
    margin: 0px;
}
h3,h4{
    font-family: Frutiger-Roman,Helvetica,Arial,sans-serif;
    color: #424249;
    font-size: 20px;
    padding:0px;
    margin: 0px;
}
a{
    color: #0470c5;
    font-size: 14px;
}
a.btn.btn-link,
button.btn.btn-link{
    /* padding:0px !important; */
    text-decoration: none;
    color: #0470c5;
}
a.btn.btn-link:hover,
button.btn.btn-link:hover{
    text-decoration: underline;
}
.alert{
    padding: 15px 20px;
    font-family: Frutiger-Bold,Helvetica,Arial,sans-serif;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 0px;
    margin-bottom: 0px;
}
.alert.shadow{
    box-shadow: 0 4px 6px rgb(0 0 0 / 20%);
}
.alert i:first-child{
    font-size: 24px;
    vertical-align: middle;
    margin-right: 10px;
}
.alert i:last-child{
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
}
.alert span{
    width: 94%;
    display: inline-block;
    vertical-align: middle;
}
table tr td p{
    margin: 0px !important;
    display: contents;
}
.fontbold{
    font-family: Frutiger-Bold,Helvetica,Arial,sans-serif!important;
}
.alert.resultsflag{
    font-family: Frutiger-Bold!important;
    border-radius: 25px;
    padding: 6px 8px;
    text-align: center;
    font-size: 13px;
    font-weight: 400!important;
    margin: 0;
    border-color: #fff!important;
}
.alert-danger {
    background-color: #fff4f4!important;
    color: #c60f13;
}
.alert-success{
    background-color:#e4f3e4!important;
    color:#007a4b
}
.alert-primary{
    color: #1565c0 !important;
     background-color:#e4f3e4!important;
}
select.dropdown:not(.inpError){
    border:solid 2px gray;
}
select.dropdown:not(.inpError):focus
{
    outline-width: 3px;
    outline-style: dashed;
    outline-color: #0470C5;
    box-shadow: 0 0 12px 0 #1595fa;

    background-color: #fff;
    height: 44px;
    border: none;
}
input[type="text"]:not(.inpError){
    border:solid 2px gray;
}

input[type="text"]:not(.inpError):focus
{
    /* input[type="text"]:focus{ */
    outline:solid 2px #0470c5;
    border: none;
    outline-style: dashed;
    box-shadow: 0 0 10px #0470c5;
    background-color: #fff;
}
input[type="checkbox"]:not(.inpError):focus
{
    /* input[type="text"]:focus{ */
    outline:solid 2px #0470c5;
    border: none;
    outline-style: dashed;
    box-shadow: 0 0 10px #0470c5;
}
select.responsive{
    width: auto !important;
}
.inpError,
.inpError:focus{
    border: none;
    outline:2px solid #c60f13;
    box-shadow: 0 0 10px #c60f13;
    background-color: #fff4f4;
}
*:has(>datepicker) {
    padding:0px !important;
}
.content-card{
    -moz-box-shadow:  0 4px 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow:  0 4px 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    color:#626f7d!important;
    /* overflow: auto; */
}
.content-card .card-header,
.content-card .card-content{
    padding:26px;
    border-radius: 4px 4px 0 0;
}
.content-card .card-header:not(.noborder){
    border-bottom: 2px solid #ddd;
}
.content-card .card-footer{
    background-color: rgba(242,246,249,1);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding:26px;
}
.nopadding{
    padding:0px !important;
}
.nomargin{
    margin:0px !important;
}
.notfavorite{
    background-image: url(assets/images/notfavorite.png);
    width: 28px;
    height: 28px;
    background-size: 28px 28px;
    background-repeat: no-repeat;
    display: inline-block;
}
.favorite{
    background-image: url(assets/images/favorite.png);
    width:28px;
    height: 28px;
    background-size: 28px 28px;
    background-repeat: no-repeat;
    display: inline-block;
}
.radioInput.disabled,
.radioInput.disabled *{
    opacity: 0.8;
}
.radioInput input[type="radio"]{
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
    cursor: pointer;
}
@font-face {
	font-family: "Frutiger-Roman";
	font-weight: 400;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Roman.otf"); */
	
	src: url('./assets/fonts/FrutigerRoman.eot'); 
    src: url('./assets/fonts/FrutigerRoman.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerRoman.woff') format('woff'),
         url('./assets/fonts/FrutigerRoman.ttf')  format('truetype'),
         url('./assets/fonts/FrutigerRoman.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Light";
	font-weight: 300;
 	font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Light.otf"); */
	
	src: url('./assets/fonts/FrutigerLight.eot');
    src: url('./assets/fonts/FrutigerLight.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerLight.woff') format('woff'),
         url('./assets/fonts/FrutigerLight.ttf')  format('truetype'),
         url('./assets/fonts/FrutigerLight.svg') format('svg');
}
@font-face {
	font-family: "Frutiger-Bold";
	font-weight: 600;
    font-style: normal;
	/* src: url("./assets/fonts/FrutigerLTStd-Bold.otf"); */
	
	src: url('./assets/fonts/FrutigerBold.eot');
    src: url('./assets/fonts/FrutigerBold.eot?#iefix') format('embedded-opentype'),
         url('./assets/fonts/FrutigerBold.woff') format('woff'),
         url('./assets/fonts/FrutigerBold.ttf')  format('truetype'), 
         url('./assets/fonts/FrutigerBold.svg') format('svg');
}
.savedMembersModal,
.modalDafault{
    min-height: 300px;
    overflow:auto;
}
.loadingContent{
    top:40%;
}
.loadingContent1{
    top:20%;
}
.cancelPAConfirmation{
    top:20%;
}

.textcapitalize{
    text-transform: capitalize;
}
i.fa-close:hover{
    cursor: pointer;
}
.fa.fa-ban,
.fa.fa-exclamation-circle,
.fa.fa-exclamation-triangle.error{
    font-size: 40px;
    color: #c60f13;
    position: relative;
}
.fa-exclamation-triangle:not(.error){
    color: rgb(143,101,43);
    font-size:35px;
}
.fa-angle-right,
.fa-angle-left{
    font-size: 32px!important; 
    color: #0470c5;
}
.fa.fa-check-circle{
    font-size: 50px;
    color: green;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 85%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 75%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
    .modal-sm,
    .modal-lg,
    .modal-xl{
        max-width: 65%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {
    .modal-sm{
        max-width: 50%;
    }
    .modal-lg{
        max-width:65%;
    }
    .modal-xl{
        max-width: 75%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
    .modal-sm{
        max-width: 40%;
    }
    .modal-lg{
        max-width:75%;
    }
    .modal-xl{
        max-width: 75%;
    }    
}
.bigloader i.fa-spin{
    font-size: 50px !important;
}
/********************************EPA module related styles start***************************************/

/********************************EPA module related styles end***************************************/
